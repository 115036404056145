// src/hooks/queries/useAccessTokenQuery.js
import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "@/hooks/queries/useAccessToken";
import { fallbackLogErrorToServer } from "@/utils/fallbackLogErrorToServer";

export const useAccessTokenQuery = () => {
	const accessToken = useAccessToken();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: ["accessToken"],
		queryFn: async () => accessToken,
		staleTime: 30 * 60 * 1000, // 30 minutes
		cacheTime: 25 * 60 * 60 * 1000, // 25 hours
		refetchInterval: 6 * 60 * 60 * 1000, // Every 6 hours
		enabled: !!accessToken, // Only run query when accessToken is available
		onError: (error) => {
			fallbackLogErrorToServer({
				message: error.message,
				stack: error.stack,
				type: "AccessTokenQueryError",
				additionalInfo: { detail: "Error in React Query while fetching access token" },
			});
		},
	});
};
