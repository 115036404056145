// src/hooks/queries/useAccessToken.js
import { useUser } from "@auth0/nextjs-auth0/client";
import { jwtDecode } from "jwt-decode"; // Corrected named import
import { useState, useEffect, useCallback, useRef } from "react";
import { fallbackLogErrorToServer } from "@/utils/fallbackLogErrorToServer";

export const useAccessToken = () => {
	const { user } = useUser();
	const [accessToken, setAccessToken] = useState(null);
	const isRefreshing = useRef(false); // Changed from state to ref
	const refreshQueue = useRef([]);
	const refreshTimerId = useRef(null);

	const refreshAccessToken = useCallback(async () => {
		if (isRefreshing.current) {
			return new Promise((resolve, reject) => {
				refreshQueue.current.push({ resolve, reject });
			});
		}

		isRefreshing.current = true;

		try {
			const response = await fetch("/api/auth/getRefreshToken");
			const data = await response.json();
			if (!response.ok) {
				throw new Error(data.error || "Failed to refresh access token");
			}
			setAccessToken(data.accessToken);

			// Resolve all queued promises with the new token
			refreshQueue.current.forEach(({ resolve }) => resolve(data.accessToken));
			refreshQueue.current = [];

			return data.accessToken;
		} catch (error) {
			fallbackLogErrorToServer({
				message: error.message,
				stack: error.stack,
				type: "AccessTokenRefreshError",
				auth0UserId: user?.sub || null,
				additionalInfo: { detail: "Failed during access token refresh" },
			});

			// Reject all queued promises
			refreshQueue.current.forEach(({ reject }) => reject(error));
			refreshQueue.current = [];

			throw error;
		} finally {
			isRefreshing.current = false;
		}
	}, [user]); // Removed isRefreshing from dependencies

	const fetchAccessToken = useCallback(async () => {
		try {
			const response = await fetch("/api/auth/getAccessToken");
			const data = await response.json();
			if (!response.ok) {
				if (response.status === 401) {
					return await refreshAccessToken();
				}
				throw new Error(data.error || "Failed to fetch access token");
			}
			setAccessToken(data.accessToken);
			return data.accessToken;
		} catch (error) {
			fallbackLogErrorToServer({
				message: error.message,
				stack: error.stack,
				type: "AccessTokenError",
				auth0UserId: user?.sub || null,
				additionalInfo: { detail: "Error while fetching access token" },
			});
			throw error;
		}
	}, [refreshAccessToken, user]);

	useEffect(() => {
		if (!user) {
			setAccessToken(null);
			return;
		}

		const initiateTokenRefresh = async () => {
			try {
				const token = await fetchAccessToken();
				if (token) {
					const decoded = jwtDecode(token);
					const exp = decoded.exp * 1000; // Convert expiration time to milliseconds
					const currentTime = Date.now();
					const refreshTime = exp - currentTime - 60 * 60 * 1000; // Refresh 1 hour before expiry

					if (refreshTime <= 0) {
						// Token is expired or about to expire, refresh immediately
						await refreshAccessToken();
					} else {
						refreshTimerId.current = setTimeout(async () => {
							try {
								await refreshAccessToken();
							} catch (error) {
								fallbackLogErrorToServer({
									message: error.message,
									stack: error.stack,
									type: "ProactiveRefreshError",
									auth0UserId: user?.sub || null,
									additionalInfo: { detail: "Error during proactive token refresh" },
								});
							}
						}, refreshTime);
					}
				} else {
					fallbackLogErrorToServer({
						message: "No access token received",
						stack: "fetchAccessToken returned null",
						type: "AccessTokenError",
						auth0UserId: user?.sub || null,
						additionalInfo: { detail: "Token is null during proactive refresh" },
					});
				}
			} catch (error) {
				fallbackLogErrorToServer({
					message: error.message,
					stack: error.stack,
					type: "ProactiveRefreshError",
					auth0UserId: user?.sub || null,
					additionalInfo: { detail: "Error during initial token fetch in useEffect" },
				});
			}
		};

		initiateTokenRefresh();

		return () => {
			if (refreshTimerId.current) {
				clearTimeout(refreshTimerId.current);
			}
		};
	}, [fetchAccessToken, refreshAccessToken, user]);

	return accessToken;
};
